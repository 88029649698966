import React, { useEffect, useRef, useState } from "react";
import "./expirationDateComponent.scss";

export const ExpirationDateComponent = React.forwardRef((props, ref) => {
  const { name, disabled, read_only } = props;
  const inputRef = useRef(null);

  // Format date to YYYY-MM-DD if it exists
  const formatDate = (date) => {
    if (!date) return "";
    try {
      return new Date(date).toISOString().split("T")[0];
    } catch (e) {
      console.error("Date formatting error:", e);
      return date;
    }
  };

  // Get the initial value from various possible locations
  const getInitialValue = () => {
    const value =
      props.defaultValue ||
      props.value ||
      (props.data && (props.data.defaultValue || props.data.value)) ||
      "";
    return formatDate(value);
  };

  const [dateValue, setDateValue] = useState(getInitialValue());

  useEffect(() => {
    if (ref) {
      ref.current = inputRef.current;
    }
  }, [ref]);

  useEffect(() => {
    const newValue = getInitialValue();
    setDateValue(newValue);
  }, [props.defaultValue, props.value, props.data]);

  const formatLabel = (label) => {
    return label ? label.replace(/</g, "&lt;").replace(/>/g, "&gt;") : "";
  };

  const getComponentName = () => {
    // Get the component name from the parent's label if available
    const parentLabel = props.data?.parentProps?.data?.label;
    if (parentLabel) {
      // Remove any HTML tags and trim the result
      return parentLabel.replace(/<\/?[^>]+(>|$)/g, "").trim();
    }
    return "";
  };

  const handleDateChange = (e) => {
    const newValue = e.target.value;
    setDateValue(newValue);
    if (props.onChange) {
      props.onChange(name, newValue);
    }
  };

  const componentName = getComponentName();
  const labelText = componentName
    ? `${componentName} Expiration Date`
    : "Expiration Date";

  return (
    <div className="modern-date-input">
      <label className="date-label">
        <span
          dangerouslySetInnerHTML={{
            __html: formatLabel(labelText),
          }}
        ></span>
        {props.data?.required ? (
          <span className="required-mark">*</span>
        ) : (
          <></>
        )}
      </label>
      <div className="date-input-wrapper form-group">
        <input
          ref={inputRef}
          type="date"
          className="modern-date-control"
          name={name}
          disabled={disabled}
          readOnly={read_only}
          value={dateValue}
          onChange={handleDateChange}
          required={props.data?.required}
        />
        <div className="date-icon">📅</div>
      </div>
    </div>
  );
});
