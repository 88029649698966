import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  collection,
  getDoc,
  doc,
  addDoc,
  serverTimestamp,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useLocation, useParams } from "react-router-dom";
import { ReactFormGenerator, Registry } from "react-form-builder2";
import "./answerRigcheck.scss";
import { RotatingLines } from "react-loader-spinner";
import "react-form-builder2/dist/app.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PassFailComponent } from "./passFailComponent";
import { CommentComponent } from "./commentComponent";
import { SealComponent } from "./sealComponent";
import { ReplaceQuestionComponent } from "./replaceQuestionComponent";
import { SealedBrokenComponent } from "./sealedBrokenComponent";
import { SealNumberComponent } from "./sealNumberComponent";
import { NewSealNumberComponent } from "./newSealNumberComponent";
import { ExpirationDateComponent } from "./expirationDateComponent";
import "./expirationDateComponent.scss";
import Modal from "react-modal";
import CodeModal from "../../components/codeModal/codeModal";

const AnswerRigcheck = () => {
  const [checkData, setCheckData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showingPin, setShowingPin] = useState(false);
  const [userId, setUid] = useState("");
  const [draftData, setDraftData] = useState(null);
  const [showDraftPrompt, setShowDraftPrompt] = useState(false);
  const { agency } = useParams();
  const containerRef = useRef(null);
  const { checkId } = useParams();
  const formGeneratorRef = useRef(null);
  const [storedFormattedDate, setStoredFormattedDate] = useState(null);
  const saveTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRadioChange = () => {
    const sealedRadio = containerRef.current.querySelectorAll(
      'input[type="radio"][value="sealed"]'
    );
    const brokenRadio = containerRef.current.querySelectorAll(
      'input[type="radio"][value="broken"]'
    );
    const noRadio = containerRef.current.querySelectorAll(
      'input[type="radio"][value="no"]'
    );
    const yesRadio = containerRef.current.querySelectorAll(
      'input[type="radio"][value="yes"]'
    );

    sealedRadio.forEach((radio) => {
      radio.addEventListener("change", (e) => {
        if (e.target.checked) {
          const replaceDiv = radio
            .closest(".SortableItem")
            .nextElementSibling.querySelector(".replace");
          replaceDiv.style.display = "none";
        }

        // if one sibling up is has a seal-number-input and the value is empty, change the display of the .new-seal-number div from two sibings down
        const sealNumber = radio
          .closest(".SortableItem")
          .previousElementSibling.querySelector(".seal-number");
        if (sealNumber.querySelector("input").value === "") {
          const replaceQuestionSortableItem =
            radio.closest(".SortableItem").nextElementSibling;
          const newSealNumberDiv = replaceQuestionSortableItem
            .closest(".SortableItem")
            .nextElementSibling.querySelector(".new-seal-number");
          newSealNumberDiv.style.display = "block";
        }
      });
    });

    brokenRadio.forEach((radio) => {
      radio.addEventListener("change", (e) => {
        if (e.target.checked) {
          const replaceDiv = radio
            .closest(".SortableItem")
            .nextElementSibling.querySelector(".replace");
          replaceDiv.style.display = "block";
        }

        // if one sibling up is has a seal-number-input and the value is empty, hide the .new-seal-number div from two sibings down
        // Unless yes is checked in the replace question.
        const sealNumber = radio
          .closest(".SortableItem")
          .previousElementSibling.querySelector(".seal-number");
        if (sealNumber.querySelector("input").value === "") {
          const replaceQuestionSortableItem =
            radio.closest(".SortableItem").nextElementSibling;
          const replaceQuestionRadio =
            replaceQuestionSortableItem.querySelector(
              'input[type="radio"][value="yes"]'
            );
          if (replaceQuestionRadio.checked) {
            console.log("yes is checked");
          } else {
            const newSealNumberDiv = replaceQuestionSortableItem
              .closest(".SortableItem")
              .nextElementSibling.querySelector(".new-seal-number");
            newSealNumberDiv.style.display = "none";
          }
        }
      });
    });

    noRadio.forEach((radio) => {
      radio.addEventListener("change", (e) => {
        if (e.target.checked) {
          const replaceDiv = radio
            .closest(".SortableItem")
            .nextElementSibling.querySelector(".new-seal-number");
          replaceDiv.style.display = "none";
        }
      });
    });

    yesRadio.forEach((radio) => {
      radio.addEventListener("change", (e) => {
        if (e.target.checked) {
          const replaceDiv = radio
            .closest(".SortableItem")
            .nextElementSibling.querySelector(".new-seal-number");
          replaceDiv.style.display = "block";
        }
      });
    });
  };
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      uid: params.get("uid"),
      // Add more as needed
    };
  };
  useEffect(() => {
    const queryParams = getQueryParams();

    console.log("Query Parameters:", queryParams);

    // Perform actions based on query params
    if (queryParams.uid) {
      setUid(queryParams.uid);
      checkForDraft(queryParams.uid);
      getDoc(doc(db, `users/${queryParams.uid}`)).then((doc) => {
        if (doc.exists()) {
          if (!doc.data().disabled) {
            setShowingPin(false);
          }
        }
      });
      // Logic based on param1
    }
  }, [location.search]);

  useEffect(() => {
    if (!loading) {
      // query all elements with class seal-number-input
      const sealNumberInputs = document.querySelectorAll(".seal-number-input");
      const sealNumbers = checkData.sealNumbers;
      // loop through all elements with class seal-number-input and set the value to the corresponding value in sealNumbers. Its corresponding is based on index.
      if (sealNumbers && sealNumbers.length) {
        sealNumberInputs.forEach((input, index) => {
          input.value = sealNumbers[index]
            ? sealNumbers[index].value || ""
            : "";
        });
      } else {
        sealNumberInputs.forEach((input) => {
          input.value = "";
        });
      }

      handleRadioChange();
    }
  }, [loading, checkData]);

  // Add expiration date input for checkbox options with hasExpiration property
  useEffect(() => {
    if (!loading && checkData.form) {
      try {
        const formData = JSON.parse(checkData.form);
        
        // Find all checkbox options that have hasExpiration set to true
        const checkboxesWithExpiration = formData.filter(field => 
          field.element === "Checkboxes" && 
          field.options && 
          field.options.some(option => option.hasExpiration === true)
        );
        
        // For each checkbox field with hasExpiration options
        setTimeout(() => {
          checkboxesWithExpiration.forEach(field => {
            field.options.forEach((option, index) => {
              const fieldContainer = document.querySelector(`[name^="option_${option.key}"]`)?.closest('.form-group');
              if (fieldContainer) {
                // Find which options have hasExpiration
                if (option.hasExpiration === true) {
                  // Find the specific checkbox option
                  const optionElement = fieldContainer.querySelectorAll('.custom-control.custom-checkbox')[index];
                  
                  if (optionElement) {
                    // Create a container for the ExpirationDateComponent
                    const expirationContainer = document.createElement('div');
                    expirationContainer.className = 'modern-date-input';
                    
                    // Get the checkbox option label/text
                    const checkboxLabel = optionElement.querySelector('label');
                    const optionText = checkboxLabel ? checkboxLabel.textContent.trim() : '';
                    const labelText = optionText ? `${optionText} Expiration Date` : 'Expiration Date';
                    
                    // Create a label with the proper styling
                    const expirationLabel = document.createElement('label');
                    expirationLabel.className = 'date-label';
                    const labelSpan = document.createElement('span');
                    labelSpan.textContent = labelText;
                    expirationLabel.appendChild(labelSpan);
                    
                    // Create the input wrapper
                    const inputWrapper = document.createElement('div');
                    inputWrapper.className = 'date-input-wrapper form-group';
                    
                    // Create the expiration date input
                    const expirationInput = document.createElement('input');
                    expirationInput.type = 'date';
                    expirationInput.className = 'modern-date-control';
                    expirationInput.name = `${option.key}_expiration`;
                    expirationInput.value = option.expiration_date || '';
                    
                    // Create the date icon element
                    const dateIcon = document.createElement('div');
                    dateIcon.className = 'date-icon';
                    dateIcon.textContent = '📅';
                    
                    // Append elements in the correct structure
                    expirationContainer.appendChild(expirationLabel);
                    inputWrapper.appendChild(expirationInput);
                    inputWrapper.appendChild(dateIcon);
                    expirationContainer.appendChild(inputWrapper);
                    optionElement.appendChild(expirationContainer);
                    
                  }
                }
              }
            });
          });
        }, 500); // Small delay to ensure DOM is fully rendered
      } catch (error) {
        console.error("Error processing form data for hasExpiration:", error);
      }
    }
  }, [loading, checkData]);

  const handlePinSubmit = async (data) => {
    let pin = data.join("");

    let agencySettingsDoc = await getDoc(
      doc(db, `Agencies/${agency}/Settings/PIN`)
    );

    if (agencySettingsDoc.exists()) {
      let agencyPin = agencySettingsDoc.data().value;

      if (pin === agencyPin) {
        setShowingPin(false);
      } else {
        toast.error("Invalid PIN", {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const handleSubmit = async (data) => {
    if (showingPin) return;
    // handle validation for new seal number
    const sortableItems = document.querySelectorAll(".SortableItem");

    for (let i = 0; i < sortableItems.length; i++) {
      const item = sortableItems[i];
      const inputs = item.querySelectorAll("input");
      const nextItem = sortableItems[i + 1];
      if (nextItem) {
        const sealNumberInput = nextItem.querySelector(
          ".new-seal-number-input"
        );
        if (
          inputs.length &&
          inputs[0].value === "yes" &&
          inputs[0].checked &&
          sealNumberInput &&
          !sealNumberInput.value
        ) {
          toast.warn("Please enter a new seal number", {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
      }
    }

    setIsSubmitting(true);
    const colRef = collection(
      db,
      `RigcheckAnswers/OdtRgRZbYmEnNipNIft7/${agency} Rigchecks Answers`
    );

    // Process the data to include expiration dates
    const processedData = data.map((item) => {
      console.log("Item:", item);
      // Find the radio input element
      const radioInput = document.querySelector(`input[name="${item.name}"]`);
      if (radioInput) {
        console.log("Radio input:", radioInput);
        // Find the parent form-group div
        const formGroup = radioInput.closest(".form-group");
        if (formGroup) {
          // Look for the date input within this form group
          const dateInput = formGroup.querySelector('input[type="date"]');
          if (dateInput) {
            return {
              ...item,
              value: radioInput.value,
              expiration_date: dateInput.value,
            };
          }
        }
      }

      if (item.name.includes("checkboxes")) {
        // this is a checkboxes component, so grab the expiration dates
        // Grab all inputs that contain the string _expiration. 
        const expirationInputs = document.querySelectorAll('input[name*="_expiration"]');
        let expirationDatesInputs = {};
        expirationInputs.forEach(input => {
          // Extract the base name by removing "_expiration" from the input name
          const baseName = input.name.replace('_expiration', '');
          expirationDatesInputs[baseName] = input.value;
        });

        return {
          ...item,
          expiration_dates: expirationDatesInputs,
        };
      }
      return item;
    });

    
    try {
      const docRef = await addDoc(colRef, {
        answerData: processedData,
        createdAt: serverTimestamp(),
        rigcheckName: checkData.name,
        rigcheckId: checkId,
        uid: userId,
      });

      // After successful submission, delete any existing draft
      if (userId) {
        const draftRef = doc(
          db,
          "RigcheckDrafts",
          checkId,
          "drafts",
          userId
        );
        await deleteDoc(draftRef);
      }
      toast.success("Form Saved Successfully", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate(`/${agency}`);
    } catch (err) {
      toast.warn("Error Saving Form", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkForDraft = async (uid) => {
    if (!uid) return;

    try {
      const draftRef = doc(
        db,
        "RigcheckDrafts",
        checkId,
        "drafts",
        uid
      );
      const draftSnap = await getDoc(draftRef);
      console.log(draftSnap.exists());
      if (draftSnap.exists() && draftSnap.data().rigcheckId === checkId) {
        setDraftData(draftSnap.data());
        setShowDraftPrompt(true);
      }
    } catch (error) {
      console.error("Error checking for draft:", error);
    }
  };

  const handleDraftContinue = () => {
    if (draftData && draftData.answerData) {
      console.log("Loading draft data:", draftData.answerData);

      // Create a new form data object that matches the structure expected by ReactFormGenerator
      const formData = draftData.answerData.map((item) => ({
        name: item.name,
        value: Array.isArray(item.value) ? item.value[0] : item.value,
      }));

      // Use the ref to access the form generator instance
      if (formGeneratorRef.current) {
        // Set the form data
        formGeneratorRef.current.setState(
          {
            data: formData,
          },
          () => {
            // After setting state, trigger radio change handlers
            handleRadioChange();
          }
        );
      }
    }
    setShowDraftPrompt(false);
  };

  const handleDraftDiscard = async () => {
    try {
      const draftRef = doc(
        db,
        "RigcheckDrafts",
        checkId,
        "drafts",
        userId
      );
      await deleteDoc(draftRef);
      setDraftData(null);
      window.location.reload();
      setShowDraftPrompt(false);
    } catch (error) {
      console.error("Error deleting draft:", error);
      toast.error("Error deleting draft");
    }
  };

  const saveDraft = async (data) => {
    if (!userId) return;

    try {
      const draftRef = doc(
        db,
        "RigcheckDrafts",
        checkId,
        "drafts",
        userId
      );
      await setDoc(draftRef, {
        answerData: data,
        rigcheckId: checkId,
        rigcheckName: checkData.name,
        updatedAt: serverTimestamp(),
        uid: userId,
      });
    } catch (error) {
      console.error("Error saving draft:", error);
      toast.error("Error saving draft");
    }
  };

  const debouncedSaveDraft = (data) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    saveTimeoutRef.current = setTimeout(() => {
      saveDraft(data);
    }, 1200); // 2 second delay
  };

  useEffect(() => {
    const fetchRigcheck = async () => {
      const docRef = doc(
        db,
        "Rigchecks",
        "VgTv80ik2BtavT0A5owc",
        agency + " Rigchecks",
        checkId
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();        // If there's a form, parse it and look for expiration dates
        if (docData.form) {
          const formData = JSON.parse(docData.form);

          // Update all PassFailComponents that have expiration dates
          const updatedFormData = formData.map((field) => {
            if (
              field.key &&
              field.key.includes("PassFailComponent") &&
              field.expiration_date
            ) {
              // Format date as YYYY-MM-DD for HTML date input
              const date = new Date(field.expiration_date);
              const formattedDate = date.toISOString().split("T")[0];
              setStoredFormattedDate(formattedDate);
              // Set the date in the field's props
              if (!field.props) {
                field.props = {};
              }
              field.value = field.value || "pass";
              if (!field.name.includes("Quick Check")) {
                field.value = formattedDate;
              } else {
                setTimeout(() => {
                  let field_name = field.field_name + "_expiration";
                  const elements = Array.from(
                    document.getElementsByName(field_name)
                  );
                  elements.forEach((element) => {
                    if (element) {
                      element.value = formattedDate;
                    }
                  });
                }, 500);
              }
            }

            console.log("Field:", field);
            return field;
          });

          docData.form = JSON.stringify(updatedFormData);
        }

        setCheckData(docData);
      }
      setLoading(false);
    };
    fetchRigcheck();
  }, []);

  try {
    Registry.register("PassFailComponent", PassFailComponent);
    Registry.register("CommentComponent", CommentComponent);
    Registry.register("SealComponent", SealComponent);
    Registry.register("ReplaceQuestionComponent", ReplaceQuestionComponent);
    Registry.register("SealedBrokenComponent", SealedBrokenComponent);
    Registry.register("SealNumberComponent", SealNumberComponent);
    Registry.register("NewSealNumberComponent", NewSealNumberComponent);
    Registry.register("ExpirationDateComponent", ExpirationDateComponent);
  } catch (error) {}
  return (
    <div>
      <Helmet>
        {" "}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        />{" "}
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.13.0/css/all.css"
        />{" "}
      </Helmet>{" "}
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="background-container">
          <Modal
            isOpen={showDraftPrompt}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                maxWidth: "400px",
                width: "90%",
              },
            }}
          >
            <h2>Continue Draft?</h2>
            <p>
              You have an existing draft for this rigcheck. Would you like to
              continue where you left off?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={handleDraftDiscard}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#dc3545",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Discard Draft
              </button>
              <button
                onClick={handleDraftContinue}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#28a745",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Continue Draft
              </button>
            </div>
          </Modal>

          <div
            className="form-generator-container"
            ref={containerRef}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.type !== "textarea") {
                e.preventDefault();
              }
            }}
          >
            <div className="form-title">{checkData.name}</div>
            <div className="required-text">
              Fields marked with an <span style={{ color: "red" }}>*</span> are
              required
            </div>
            <ReactFormGenerator
              ref={formGeneratorRef}
              data={JSON.parse(checkData.form)}
              answer_data={draftData ? draftData.answerData : []}
              back_action={`/${agency}`}
              back_name="Back"
              onSubmit={handleSubmit}
              onChange={(data) => debouncedSaveDraft(data)}
              submitButton={
                <button type="submit" className="btn" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              }
            />
          </div>
          <Modal
            isOpen={showingPin}
            style={{
              content: {
                backgroundColor: "transparent",
                border: "none",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
            contentLabel="Example Modal"
          >
            <CodeModal submit={handlePinSubmit} />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AnswerRigcheck;
